<template>
  <section>
     <alunoLista :pg="this.pg" />
  </section>
</template>

<script>
import { defineComponent } from "vue";
import alunoLista from "@/components/alunos/alunoAll.vue"

export default defineComponent({
  props:{
    pg: {
      default:1
    },
  },
  components:{
    alunoLista
  },
  data() {
    return {
    };
  },
});
</script>
